import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { Color } from '../obj.constants/constants';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: Color.PrimaryLight,
      main: Color.Primary,
    },
    secondary: {
      light: Color.SecondaryLight,
      main: Color.Secondary,
      dark: Color.SecondaryDark,
    },
    error: {
      main: Color.Error,
    },
    success: {
      main: Color.Success,
    },
    warning: {
      main: Color.Warning,
    },
    neutral: {
      light: Color.GrayLight,
      main: Color.GrayDark,
      dark: Color.GrayLight,
    },
  },
  typography: {
    fontFamily: `"Montserrat", "Roboto", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    h1: {
      fontSize: '2.5rem',
    },
  },
});
const CustomThemeProvider: React.FunctionComponent = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default CustomThemeProvider;
