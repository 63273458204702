// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cotacao-tsx": () => import("./../../../src/pages/cotacao.tsx" /* webpackChunkName: "component---src-pages-cotacao-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parceiros-tsx": () => import("./../../../src/pages/parceiros.tsx" /* webpackChunkName: "component---src-pages-parceiros-tsx" */),
  "component---src-pages-produtos-tsx": () => import("./../../../src/pages/produtos.tsx" /* webpackChunkName: "component---src-pages-produtos-tsx" */),
  "component---src-pages-quem-somos-tsx": () => import("./../../../src/pages/quem-somos.tsx" /* webpackChunkName: "component---src-pages-quem-somos-tsx" */),
  "component---src-templates-company-tsx": () => import("./../../../src/templates/company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

