import { Link } from 'gatsby';
import styled from 'styled-components';

export const UnstyledLink = styled(Link)`
  color: unset;
  text-decoration: none;
`;

export const UnstyledNativeLink = styled.a`
  color: unset;
  text-decoration: none;
`;
