import {
  AppBar,
  Box,
  Button,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import { graphql, Link, useStaticQuery } from 'gatsby';

import { FunctionComponent } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import HeaderDrawer from '../mol.header-drawer/header-drawer.component';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { HeaderLinkButtonStyled } from '../org.header/header.styled';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';

export interface HeaderLink {
  text: string;
  href: string;
}

interface IHeaderProps {}

export const Header: FunctionComponent<IHeaderProps> = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      markdownRemark(frontmatter: { fileName: { eq: "headerInfos" } }) {
        frontmatter {
          headerAboutUsLinkText
          headerCompanyText
          headerInsuranceCompaniesLinkText
          headerProductsLinkText
          headerQuotationLinkText
          headerLogo {
            childImageSharp {
              gatsbyImageData(height: 34)
            }
          }
        }
      }
    }
  `);
  const headerData = data.markdownRemark.frontmatter;
  const headerLinks: HeaderLink[] = [
    { text: headerData.headerQuotationLinkText, href: '/cotacao' },
    { text: headerData.headerProductsLinkText, href: '/produtos' },
    { text: headerData.headerInsuranceCompaniesLinkText, href: '/parceiros' },
    { text: headerData.headerAboutUsLinkText, href: '/quem-somos' },
  ];
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <>
      <AppBar color={'primary'} position="fixed">
        <Toolbar>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item md={4}>
              <Box textAlign="center">
                <UnstyledLink to={'/'}>
                  <Button color="inherit">
                    <Box marginRight={2}>
                      <GatsbyImage
                        image={
                          headerData.headerLogo.childImageSharp.gatsbyImageData
                        }
                        alt="Logo image"
                        objectFit="contain"
                      />
                    </Box>
                    <Typography variant="h6" component="h2">
                      {headerData.headerCompanyText}
                    </Typography>
                  </Button>
                </UnstyledLink>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item md={8}>
                {headerLinks.map((link) => (
                  <UnstyledLink to={link.href} key={link.href}>
                    <HeaderLinkButtonStyled>{link.text}</HeaderLinkButtonStyled>
                  </UnstyledLink>
                ))}
              </Grid>
            </Hidden>
          </Grid>
          <Hidden mdUp>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <HeaderDrawer
              isOpen={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              links={headerLinks}
            />
          </Hidden>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};
