import { Avatar, Button } from '@material-ui/core';
import styled from 'styled-components';
import { Color, Spacing } from '../obj.constants/constants';

export const IconTextLinkButtonStyled = styled(Button)`
  && {
    text-transform: unset;
    color: ${Color.White};
    white-space: nowrap;
  }
`;

export const IconTextLinkAvatarStyled = styled(Avatar)`
  && {
    width: ${Spacing.XLarge};
    height: ${Spacing.XLarge};
    color: ${Color.Primary};
    background-color: ${Color.White};
  }
`;
