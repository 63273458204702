import * as React from 'react';
import CustomThemeProvider from '../atm.custom-theme-provider/custom-theme-provider.component';
import { Header, HeaderLink } from '../org.header/header.component';
import SEO from '../seo';
import '@fontsource/montserrat';
import { ContentWrapper, PageLayoutStyled } from './page-layout.styled';
import { Footer, FooterLink } from '../org.footer/footer.component';
import '../layout.css';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

interface IPageLayoutProps {}

const PageLayout: React.FunctionComponent<IPageLayoutProps> = (props) => {
  return (
    <PageLayoutStyled>
      <CustomThemeProvider>
        <Header />
        <ContentWrapper>{props.children}</ContentWrapper>
        <Footer />
      </CustomThemeProvider>
    </PageLayoutStyled>
  );
};

export default PageLayout;
