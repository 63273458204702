import * as React from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Drawer from '@material-ui/core/Drawer';
import { Box, Divider, IconButton, makeStyles } from '@material-ui/core';
import { Color } from '../obj.constants/constants';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { UnstyledLink } from '../atm.unstyled-link/unstyled-link.styled';

export interface HeaderDrawerLinks {
  text: string;
  href: string;
}

interface IDrawerProps {
  links: HeaderDrawerLinks[];
  isOpen: boolean;
  onClose: () => any;
}

const useStyles = makeStyles({
  MuiDrawer: {
    backgroundColor: Color.Primary,
    color: Color.White,
  },
});

const HeaderDrawer: React.FunctionComponent<IDrawerProps> = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={handleClose}
      classes={{ paper: classes.MuiDrawer }}
    >
      <Box>
        <IconButton onClick={handleClose} color="inherit">
          <ChevronRightIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        {props.links.map((link) => (
          <ListItem button key={link.href}>
            <Button color="inherit" onClick={handleClose}>
              <UnstyledLink to={link.href}>{link.text}</UnstyledLink>
            </Button>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default HeaderDrawer;
