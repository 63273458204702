import { Avatar, Box, Button, Link, Typography } from '@material-ui/core';
import * as React from 'react';
import { ReactElement } from 'react';
import {
  IconTextLinkAvatarStyled,
  IconTextLinkButtonStyled,
} from './icon-text-link.styled';

interface IIconTextLinkProps {
  icon: ReactElement;
  text: string;
  href: string;
}

const IconTextLink: React.FunctionComponent<IIconTextLinkProps> = (props) => {
  return (
    <>
      <Link href={props.href} target="_blank">
        <Box display="flex" textAlign="center" alignItems="center">
          <Box>
            <IconTextLinkAvatarStyled color="primary">
              {props.icon}
            </IconTextLinkAvatarStyled>
          </Box>
          <Box>
            <IconTextLinkButtonStyled>{props.text}</IconTextLinkButtonStyled>
          </Box>
        </Box>
      </Link>
    </>
  );
};

export default IconTextLink;
