import { AppBar, Box } from '@material-ui/core';
import styled from 'styled-components';
import { Color, Spacing } from '../obj.constants/constants';

export const FooterStyled = styled(Box)`
  position: relative;
  color: ${Color.White};
  background-color: ${Color.Primary};
  padding: ${Spacing.Medium} ${Spacing.Large} ${Spacing.Medium} ${Spacing.Large};
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
`;
