import { FunctionComponent, ReactElement } from 'react';
import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { FooterStyled } from './footer.styled';
import IconTextLink from '../mol.icon-text-link/icon-text-link.component';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStaticQuery, graphql } from 'gatsby';
import PhoneIcon from '@material-ui/icons/Phone';
import { faWhatsapp, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { getTelLink, getWhatsappLink } from '../../utils/links';

export interface FooterLink {
  text: string;
  href: string;
  icon: ReactElement;
}

interface IFooterProps {}

export const Footer: FunctionComponent<IFooterProps> = (props) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      markdownRemark(frontmatter: { fileName: { eq: "footerInfos" } }) {
        frontmatter {
          footerCompanyText
          footerCopyrightText
          footerFacebook {
            text
            link
          }
          footerPhone {
            link
            text
          }
          footerWhatsapp {
            link
            text
            message
          }
        }
      }
    }
  `);
  const footerData = data.markdownRemark.frontmatter;
  const footerLinks: FooterLink[] = [
    {
      text: footerData.footerFacebook.text,
      href: footerData.footerFacebook.link,
      icon: <FontAwesomeIcon icon={faFacebookF} />,
    },
    {
      text: footerData.footerWhatsapp.text,
      href: getWhatsappLink(
        footerData.footerWhatsapp.link,
        footerData.footerWhatsapp.message
      ),
      icon: <FontAwesomeIcon icon={faWhatsapp} />,
    },
    {
      text: footerData.footerPhone.text,
      href: getTelLink(footerData.footerPhone.link),
      icon: <PhoneIcon />,
    },
  ];
  return (
    <>
      <FooterStyled color={'primary'} position="fixed">
        <Grid container spacing={2}>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={2}
            item
            xs={12}
            md={3}
          >
            <Grid item xs={12}>
              <Box>
                <Button href="/" color="inherit">
                  <Typography variant="h6">
                    {footerData.footerCompanyText}
                  </Typography>
                </Button>
              </Box>
              <Box paddingLeft={1}>
                <Typography variant="caption">
                  <FontAwesomeIcon icon={faCopyright} />{' '}
                  {footerData.footerCopyrightText}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={2}
            item
            xs={12}
            md={9}
          >
            {footerLinks.map((link) => (
              <Grid item xs key={link.text}>
                <IconTextLink
                  text={link.text}
                  icon={link.icon}
                  href={link.href}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </FooterStyled>
    </>
  );
};
