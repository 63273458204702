
  
  // We used class rather than enums since enums don't support computed values
  export class Color {
    public static readonly Black = '#0F0F0F'
    public static readonly White = '#FFFFFF'
  
    public static readonly GrayBackground = '#F2F2F2'
    public static readonly GrayLight = '#ECECEC'
    public static readonly Gray = '#616161'
    public static readonly GrayDark = '#212121'
  
    public static readonly PrimaryLight = '#B3E5FE'
    public static readonly Primary = '#002E4A'

    public static readonly SecondaryLight = '#EEB08C'
    public static readonly Secondary = '#DE6E0B'
    public static readonly SecondaryDark = '#C76103'


    public static readonly Background = Color.White


    public static readonly Error = '#FC4D4D'
    public static readonly Warning = '#FBEA4F'
    public static readonly Success = '#66E503'
  }
  
  export enum FontFamily {
    Primary = 'Roboto, OpenSans'
  }
  
  export enum FontWeight {
    Bold = 'bold',
    SemiBold = 600,
    Normal = 'normal',
    Lighter = 200
  }
  
  export enum FontSize {
    XXSmall = '12px',
    XSmall = '14px',
    Small = '16px',
    Medium = '18px',
    Large = '24px',
    XLarge = '32px'
  }
  
  export enum Spacing {
    XSmall = '4px',
    Small = '8px',
    Medium = '16px',
    Large = '24px',
    XLarge = '32px',
    XXLarge = '60px'
  }
  
  export enum IconSize {
    Small = '12px',
    Medium = '16px',
    Large = '20px'
  }
  
  export const Border = {
    Color: Color.GrayLight,
    Width: '1px',
    Radius: '8px',
    RadiusLarge: '24px'
  }
  
  export const HeaderHeight = {
    Mobile: 64,
    Desk: 108
  }
  
  export const DrawerWidth = '256px'
  export const TransitionDuration = '.3s'
  
  // Security recommendation: Failing to enforce passwords of at least 7 characters, a complexity
  // of at least alpha and numeric characters increases the risk of a brute force attack.
  export enum PasswordLength {
    Max = 18,
    Min = 7
  }
  
  export enum AspectRatio {
    Square = 1
  }
  