function getPhoneWithouSpecials(phone) {
  const phoneString = `${phone}`;
  return phoneString.replace(/\D/g, '');
}

function getWhatsappLink(phone, text) {
  return `https://wa.me/${getPhoneWithouSpecials(
    phone
  )}?text=${encodeURIComponent(text)}`;
}

function getTelLink(phone) {
  return `tel:+${getPhoneWithouSpecials(phone)}`;
}

module.exports = {
  getWhatsappLink,
  getTelLink,
};
